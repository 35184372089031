import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby";

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'

import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import createLink from "../components/functions/createLink.js";
import gsap, { Power1 } from 'gsap';

import {
  AcquisitionDataType,
  DataStringToObjectType,
} from '../types/types.js';

import {
  createNewExpertiseArray,
  getNewsletterFields,
  updateEventFocus,
  isTagsTrueIn,
  isAllFalse,
  sliderOpenFocus,
  handleOnChange,
  handleOnSubmit,
  handleNewsletterOnSubmit,
  openFocus,
  getDate,
  sortArray,
} from '../utils/mod.js';

import "../styles/css/expertise.css"

const Expertise = ({
  doc,
  lang,
  docs,
  strategy,
  typology,
  region,
  country,
  setFocus
}) => {

  const getUrl = typeof window !== 'undefined' ? window.location.href : '';
  const newsletterFields = getNewsletterFields(doc);

  const usDate = useRef(); // true "recent" | false "ancien"
  const usAlpha = useRef(); // null nothing | true "A-Z" | false "Z-A"

  const search = useRef();
  const uRegion = useRef();
  const uTypo = useRef();
  const uStrat = useRef(); // [ true, true ] | [0] valeur ajoutée | [1] core+
  const uStatus = useRef(); // [ true, true ] | [0] cédé | [1] en portefeuille

  const focusListMax = useRef(5);

  const arrayFocus = useRef([]);
  const [focusList, setFocusList] = useState(null);
  const [uDateMin, setUDateMin] = useState(0);
  let uDateMax = new Date().getFullYear();
  const [uSurfMin, setUSurfMin] = useState(0);
  const [uSurfMax, setUSurfMax] = useState(100000);

  const [stateDate, setStateDate] = useState({ value: { min: 0, max: uDateMax } });
  const [stateSurf, setStateSurf] = useState({ value: { min: 0, max: 100000 } });
  
  const uDate = useRef({ min: uDateMin, max: uDateMax });
  const uSurf = useRef({ min: uSurfMin, max: uSurfMax });

  function updateFocusList() {
    /** @type {AcquisitionDataType[]} */
    let arrayNow = arrayFocus.current;
    arrayNow = sortArray(arrayNow, usDate, usAlpha);

    let nb = 0;
    let newFocusList = "";

    arrayNow.forEach((art) => {
      if (nb < focusListMax.current) {
        
        /** @type {DataStringToObjectType} */
        const data = JSON.parse(art[3].node.dataString);
        const title = RichText.asText(data.content_title);
        
        if (art[6] === true) {
          if (search.current === "" || title.toLowerCase().includes(search.current.toLowerCase())) {
            if (isAllFalse(uTypo)
              || (isTagsTrueIn(typology, uTypo, data.global_investments, RichText))) {
              if (isAllFalse(uRegion)
                || (isTagsTrueIn(region, uRegion, data.global_regions, RichText))) {
                if ((!uStrat.current[0] && !uStrat.current[1])
                  || (uStrat.current[0] && uStrat.current[1])
                  || (uStrat.current[0] && data.global_strategy.slug === "valeur-ajoutee")
                  || (uStrat.current[1] && data.global_strategy.slug === "core-plus")) {
                  if ((!uStatus.current[0] && !uStatus.current[1])
                    || (uStatus.current[0] && uStatus.current[1])
                    || (uStatus.current[0] && RichText.asText(data.sthumbnail_av) === "Cédé")
                    || (uStatus.current[1] && RichText.asText(data.sthumbnail_av) === "En portefeuille")) {
                    if (new Date(art[5]).getFullYear() >= uDate.current.min && new Date(art[5]).getFullYear() <= uDate.current.max) {
                      if (art[2] >= uSurf.current.min && art[2] <= uSurf.current.max) {
                        newFocusList += '<div class="el" data-uid="' + art[3].node.uid + '">' +
                          '<div class="img">' +
                          '<img src="' + data.sthumbnail_img.url + '" alt="' + data.sthumbnail_img.alt + '" loading="lazy"/>' +
                          '</div>' +
                          '<div class="text">' +
                          '<div class="head">' +
                          '<div class="date">' + getDate(data.global_date) + '</div>' +
                          '<div class="tag">' + RichText.asText(data.sthumbnail_av) + '</div>' +
                          '</div>' +
                          '<div class="title">' + title + '</div>' +
                          '<div class="place">' + RichText.asText(data.sthumbnail_adress) + '</div>' +
                          '<p>' + RichText.asText(data.content_text) + '</p>' +
                          '<div class="container-tag">';

                        for (const strat of strategy) {
                          let st = "";
                          if (strat.node.slugs[0] === data.global_strategy.slug) {
                            st = JSON.parse(strat.node.dataString).name;
                            newFocusList += '<div class="tag">' + RichText.asText(st) + '</div>';
                          }
                        }

                        for (const typo of typology) {
                          let ty = "";
                          for (let i = 0; i < data.global_investments.length; i++) {
                            if (typo.node.slugs[0] === data.global_investments[i].typo.slug) {
                              ty = JSON.parse(typo.node.dataString).name;
                              newFocusList += '<div class="tag">' + RichText.asText(ty) + '</div>';
                            }
                          }
                        }

                        for (const reg of region) {
                          let re = "";
                          let co = "";
                          for (let i = 0; i < data.global_regions.length; i++) {
                            if (reg.node.slugs[0] === data.global_regions[i].region.slug) {
                              re = JSON.parse(reg.node.dataString).name;
                              newFocusList += '<div class="tag">' + RichText.asText(re) + '</div>';
                            }
                          }

                          if (reg.node.slugs[0] === data.global_regions[0].region.slug) {
                            for (const cou of country) {
                              if (cou.node.slugs[0] === JSON.parse(reg.node.dataString).country.slug) {
                                co = JSON.parse(cou.node.dataString).name;
                              }
                            }

                            newFocusList += '<div class="tag">' + RichText.asText(co) + '</div>';
                          }
                        }

                        newFocusList += '</div>' +
                          '</div>' +
                          '</div>';
                        nb++;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    })

    if (nb >= focusListMax.current) {
      newFocusList += '<div class="container-more">' +
        '<div class="sep"></div>' +
        '<div class="container-btn">' +
        '<p>' + RichText.asText(doc.projects_more) + '</p>' +
        '</div>' +
        '</div>';
    }

    setFocusList(newFocusList);

    setTimeout(function () {
      updateEventFocus(
        focusListMax,
        openFocus,
        updateFocusList,
        docs,
        setFocus
      );
    }, 500)
  }

  /**
   * @param {string} value 
   */
  const focusSearch = (value) => {
    search.current = value;
    updateFocusList();
  };

  /**
   * @param {React.MutableRefObject<undefined>} ref 
   * @param {(value: React.SetStateAction<{ value: { min: number; max: number; } }>) => void} setState 
   * @param {number} position 
   * @param {any} value 
   */
  const updateData = (
    ref,
    setState,
    position,
    value
  ) => {
    ref.current = value;
    setState({ value })
    updateFocusList();

    document.querySelector(`.section-projects .container-filters .container-el .el:nth-child(${position}) .input-range__label--min .input-range__label-container`).innerHTML = value.min;
    document.querySelector(`.section-projects .container-filters .container-el .el:nth-child(${position}) .input-range__label--max .input-range__label-container`).innerHTML = value.max;
  };

  /**
   * @param {React.MutableRefObject<undefined>} ref 
   * @param {string} value 
   */
  const updateSortData = (ref, value) => {
    if (value === "recent" || value === "az") ref.current = true;
    else if (value === "ancien" || value === "za") ref.current = false;

    updateFocusList();
    anchorUpdate();
  };

  /**
   * @param {React.MutableRefObject<undefined>} ref 
   * @param {string} value 
   */
  const updateRegionOrTypology = (ref, value) => {
    for (const [item, bool] of ref.current) {
      if (item === value) {
        bool = !bool;
      }
    }

    updateFocusList();
    anchorUpdate();
  };

  /**
   * @param {React.MutableRefObject<undefined>} ref 
   * @param {string} value
   */
  const updateStrategyOrStatus = (ref, value) => {
    value = value.toLowerCase();

    if (value === "valeur ajoutée" || value === "cédé") {
      ref.current = [!ref.current[0], ref.current[1]];
    }

    if (value === "core plus" || value === "en portefeuille") {
      ref.current = [ref.current[0], !ref.current[1]];
    }

    updateFocusList();
    anchorUpdate();
  };

  const sortRegion = () => {
    const newRegion = region.map(reg => {
      const data = JSON.parse(reg.node.dataString);
      return [RichText.asText(data.name), 0, reg.node.prismicId];
    })

    arrayFocus.current.forEach(a => {
      const data = JSON.parse(a[3].node.dataString);

      for (const globalRegion of data.global_regions) {
        if (globalRegion.region.id) {

          for (const arr of newRegion) {
              if (globalRegion.region.id === arr[2]) {
              arr[1] = arr[1] + 1;
            }
          }
        }
      }
    })

    return newRegion.sort((a, b) => b[1] - a[1]);
  };

  function anchorUpdate() {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      let div = document.querySelector('.section-projects .wrapper .container');
      window.scrollTo(0, (div.offsetTop - 115));
    }
  }

  function cl(link) { return createLink(link, lang); }

  let windowScrollY = 0;

  const animScroll = () => {
    const section = document.querySelector('.section-investissements');
    let pos2 = (section.offsetTop) + (section.offsetHeight / 4 * 1);
    let pos3 = (section.offsetTop) + (section.offsetHeight / 4 * 2);
    let pos4 = (section.offsetTop) + (section.offsetHeight / 4 * 3);

    const [el1, el2, el3, el4] = section.querySelectorAll(".el");

    if (windowScrollY <= pos2) {
      el2.classList.remove('style-reach');
      el3.classList.remove('style-reach');
      el4.classList.remove('style-reach');
      if (!el1.classList.contains('style-reach')) {
        el1.classList.add('style-reach');
      };
    } else if (windowScrollY >= pos2 && windowScrollY <= pos3) {
      el1.classList.remove('style-reach');
      el3.classList.remove('style-reach');
      el4.classList.remove('style-reach');
      if (!el2.classList.contains('style-reach')) {
        el2.classList.add('style-reach');
      }
    } else if (windowScrollY >= pos3 && windowScrollY <= pos4) {
      el1.classList.remove('style-reach');
      el2.classList.remove('style-reach');
      el4.classList.remove('style-reach');
      if (!el3.classList.contains('style-reach')) {
        el3.classList.add('style-reach');
      }
    } else if (windowScrollY >= pos4) {
      el1.classList.remove('style-reach');
      el2.classList.remove('style-reach');
      el3.classList.remove('style-reach');
      if (!el4.classList.contains('style-reach')) {
        el4.classList.add('style-reach');
      }
    }
  };

  let mount = true;
  useEffect(() => {
    const newsletterContainer = document.querySelector('.section-newsletter .wrapper form .container-form');
    const newsletterBtn = newsletterContainer.querySelector('.container button');

    function navScroll() {
      let section = document.querySelector('.section-investissements');
      let btns = section.querySelectorAll('.container-nav .nav');

      btns.forEach(function (btn, index) {
        btn.addEventListener('click', () => {

          if (windowScrollY <= (section.offsetTop) + (section.offsetHeight / 4 * 1)) {
            if (index === 7) {
              window.scrollTo(0, (section.offsetTop) + (section.offsetHeight / 4 * 1) + 1);
            } else {
              window.scrollTo(0, (section.offsetTop) + (section.offsetHeight / 4 * 3 + 100));
            }
          }
          else if (windowScrollY >= (section.offsetTop) + (section.offsetHeight / 4 * 1) && windowScrollY <= (section.offsetTop) + (section.offsetHeight / 4 * 2)) {
            if (index === 7) {
              window.scrollTo(0, (section.offsetTop) + (section.offsetHeight / 4 * 3))
            } else {
              window.scrollTo(0, section.offsetTop + 100);
            }
          }
          else if (windowScrollY >= (section.offsetTop) + (section.offsetHeight / 4 * 2) && windowScrollY <= (section.offsetTop) + (section.offsetHeight / 4 * 3)) {
            if (index === 7) {
              window.scrollTo(0, (section.offsetTop) + (section.offsetHeight / 4 * 3 + 100));
            } else {
              window.scrollTo(0, (section.offsetTop) + (section.offsetHeight / 4 * 1 + 100));
            }
          }
          else if (windowScrollY >= (section.offsetTop) + (section.offsetHeight / 4 * 3)) {
            if (index === 7) {
              window.scrollTo(0, section.offsetTop + 100);
            } else {
              window.scrollTo(0, (section.offsetTop) + (section.offsetHeight / 4 * 3));
            }
          }
        })
      })
    }

    search.current = "";
    usDate.current = true;
    usAlpha.current = null;
    uStrat.current = [false, false];
    uStatus.current = [false, false];

    uRegion.current = region
    .map((reg) => [RichText.asText(JSON.parse(reg.node.dataString).name), false]);
    
    uTypo.current = typology
    .map((typo) => [RichText.asText(JSON.parse(typo.node.dataString).name), false]);

    arrayFocus.current = createNewExpertiseArray(
      docs,
      RichText,
      {
        setDate: setUDateMin,
        setSurfMin: setUSurfMin,
        setSurfMax: setUSurfMax
      }
    );

    /* SECTION SLIDER */
    function sectionSlider() {
      /* MOUSE */
      if (window.matchMedia("(min-width: 1200px)").matches) {
        let mousePointer = document.querySelector('.mouse-pointer');
        let mouseContainer = document.querySelector('.container-mouse');
        function moveCircle(event) {
          gsap.to(mousePointer, 0.4, {
            x: event.pageX,
            y: event.pageY,
            ease: Power1.easeOut
          });

          gsap.to(mouseContainer, 0.3, {
            x: event.pageX,
            y: event.pageY,
            ease: Power1.easeOut
          });
        }
        function stickCircle(event) {
          gsap.set(mousePointer, {
            x: event.pageX,
            y: event.pageY
          });
          gsap.set(mouseContainer, {
            x: event.pageX,
            y: event.pageY
          });
        }

        window.addEventListener('mousemove', function (event) {
          moveCircle(event);
        });
        window.addEventListener('mousewheel', function (event) {
          stickCircle(event);
        });

        let section_area = document.querySelector('.section-slider');
        let area = section_area.querySelector('.container-area');
        let area_prev = section_area.querySelector('.container-area .area:nth-child(1)');
        let area_next = section_area.querySelector('.container-area .area:nth-child(3)');
        let area_active = section_area.querySelector('.container-area .area:nth-child(2)');

        area_prev.addEventListener('mouseenter', function (event) {
          mousePointer.classList.remove('style-next', 'style-active');
          mousePointer.classList.add('style-prev');
        });
        area_next.addEventListener('mouseenter', function (event) {
          mousePointer.classList.remove('style-prev', 'style-active');
          mousePointer.classList.add('style-next');
        });
        area_active.addEventListener('mouseenter', function (event) {
          mousePointer.classList.remove('style-next', 'style-prev');
          mousePointer.classList.add('style-active');
        });

        area.addEventListener('mouseenter', function (event) {
          mousePointer.classList.add('style-show');
          mouseContainer.classList.add('style-show');
        })
        area.addEventListener('mouseleave', function (event) {
          mousePointer.classList.remove('style-show');
          mouseContainer.classList.remove('style-show');
        })
      } else {
        /* the view port is less than 1200 pixels wide */
      }

      /* END MOUSE */

      /* CAROUSEL */
      let section_slider = document.querySelector('.section-slider');
      let section_sliderBtns = section_slider.querySelectorAll('.container-area .area');
      let section_sliderEl = section_slider.querySelectorAll('.container-el .el');
      let section_sliderCount = 1;

      let section_sliderTabs = section_slider.querySelectorAll('.container-tab .tab');

      let section_sliderTags = section_slider.querySelectorAll('.li-infos');

      let section_sliderNumEl = section_sliderEl.length;

      function prevAll(elementActive) {
        while (elementActive = elementActive.previousElementSibling) {
          elementActive.classList.add('style-prev');
        }
      };
      function nextAll(elementActive) {
        while (elementActive = elementActive.nextElementSibling) {
          elementActive.classList.add('style-next');
        }
      };

      // INIT 
      function init() {
        section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
        let elActive = section_slider.querySelector('.el.style-active');
        prevAll(elActive);
        nextAll(elActive);

        section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
        let tabActive = section_slider.querySelector('.tab.style-active');
        prevAll(tabActive);
        nextAll(tabActive);

        section_slider.querySelector('.li-infos:nth-child(' + section_sliderCount + ')').classList.add('style-active');
        let infoActive = section_slider.querySelector('.li-infos.style-active');

        prevAll(infoActive);
        nextAll(infoActive);

      }
      init();

      /* SWIPE NAVIGATION */
      if (window.matchMedia("(min-width: 1200px)").matches) {
        section_sliderBtns.forEach(function (btn, index) {
          btn.addEventListener('click', function () {

            if (index === 0) {
              if (section_sliderCount > 1) {
                section_sliderCount--;
              } else if (section_sliderCount === 1) {
                section_sliderCount = section_sliderNumEl;
              }
            } else if (index === 2) {
              if (section_sliderCount < section_sliderNumEl) {
                section_sliderCount++;
              } else if (section_sliderCount === section_sliderNumEl) {
                section_sliderCount = 1;
              }
            };

            if (index === 0 || index === 2) {
              // EL
              section_sliderEl.forEach(function (el) {
                el.classList.remove('style-active', 'style-prev', 'style-next');
              });

              section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
              let elActive = section_slider.querySelector('.el.style-active');

              prevAll(elActive);
              nextAll(elActive);
              // TAB
              section_sliderTabs.forEach(function (tab) {
                tab.classList.remove('style-active', 'style-prev', 'style-next');
              });

              section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
              let tabActive = section_slider.querySelector('.tab.style-active');

              prevAll(tabActive);
              nextAll(tabActive);

              // Text
              section_sliderTags.forEach(function (info) {
                info.classList.remove('style-active', 'style-prev', 'style-next');
              });

              section_slider.querySelector('.li-infos:nth-child(' + section_sliderCount + ')').classList.add('style-active');
              let infoActive = section_slider.querySelector('.li-infos.style-active');

              prevAll(infoActive);
              nextAll(infoActive);

            }

          });
        });
      }
      else {
        section_sliderBtns[1].addEventListener("touchstart", startTouch, false);
        section_sliderBtns[1].addEventListener("touchmove", moveTouch, false);

        // Swipe Left / Right
        var initialX = null;

        function startTouch(e) {
          initialX = e.touches[0].clientX;
        };

        function moveTouch(e) {
          if (initialX === null) {
            return;
          }
          var currentX = e.touches[0].clientX;
          var diffX = initialX - currentX;

          if (diffX < 5) {
            if (section_sliderCount > 1) {
              section_sliderCount--;
            } else if (section_sliderCount === 1) {
              section_sliderCount = section_sliderNumEl;
            }
            // swiped left
          }
          else if ((diffX > -5)) {
            if (section_sliderCount < section_sliderNumEl) {
              section_sliderCount++;
            } else if (section_sliderCount === section_sliderNumEl) {
              section_sliderCount = 1;
            }
            // swiped right
          };

          if (diffX < 5 || diffX > -5) {
            // EL
            section_sliderEl.forEach(function (el) {
              el.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let elActive = section_slider.querySelector('.el.style-active');

            prevAll(elActive);
            nextAll(elActive);

            // TAB

            section_sliderTabs.forEach(function (tab) {
              tab.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let tabActive = section_slider.querySelector('.tab.style-active');

            prevAll(tabActive);
            nextAll(tabActive);

            // Text
            section_sliderTags.forEach(function (info) {
              info.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.li-infos:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let infoActive = section_slider.querySelector('.li-infos.style-active');

            prevAll(infoActive);
            nextAll(infoActive);
          }

          initialX = null;

          e.preventDefault();
        };
        /* END SWIPE NAVIGATION */
      }

      /* END CAROUSEL */

      /* AUTO PLAY */
      function autoplay() {
        var isPaused = false;

        let timeInterval = section_slider.querySelector('.play').getAttribute('data-interval');
        window.setInterval(function () {
          if (!isPaused) {

            if (section_sliderCount < section_sliderNumEl) {
              section_sliderCount++;
            } else if (section_sliderCount === section_sliderNumEl) {
              section_sliderCount = 1;
            }

            // EL
            section_sliderEl.forEach(function (el) {

              el.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.el:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let elActive = section_slider.querySelector('.el.style-active');

            prevAll(elActive);
            nextAll(elActive);

            // TAB
            section_sliderTabs.forEach(function (tab) {
              tab.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.tab:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let tabActive = section_slider.querySelector('.tab.style-active');

            prevAll(tabActive);
            nextAll(tabActive);

            // Text
            section_sliderTags.forEach(function (info) {
              info.classList.remove('style-active', 'style-prev', 'style-next');
            });

            section_slider.querySelector('.li-infos:nth-child(' + section_sliderCount + ')').classList.add('style-active');
            let infoActive = section_slider.querySelector('.li-infos.style-active');

            prevAll(infoActive);
            nextAll(infoActive);

          }
        }, timeInterval);

        let play = section_slider.querySelector('.play');
        play.addEventListener('click', function (e) {
          play.classList.toggle('style-play');
          if (play.classList.contains('style-play')) {
            e.preventDefault();
            isPaused = false;
          } else {
            e.preventDefault();
            isPaused = true;
          }

        })
        if (window.matchMedia("(min-width: 1200px)").matches) {
          section_sliderBtns.forEach(function (btn, index) {
            btn.addEventListener('click', () => {
              if (index === 0 || index === 2) {
                isPaused = true;
                play.classList.remove('style-play');
              }
            })
          })
        }
        else {
          section_sliderBtns[1].addEventListener("touchstart", startTouch, false);
          section_sliderBtns[1].addEventListener("touchmove", moveTouch, false);

          // Swipe Left / Right
          var initialX = null;

          function startTouch(e) {
            initialX = e.touches[0].clientX;
          };

          function moveTouch(e) {
            if (initialX === null) {
              return;
            }
            var currentX = e.touches[0].clientX;
            var diffX = initialX - currentX;

            if (diffX > 10 || diffX < -10) {
              isPaused = true;
              play.classList.remove('style-play');
            }

            initialX = null;

            e.preventDefault();
          };
        }
      };

      init();
      autoplay();
    };
    /* END SECTION SLIDER */

    /* FILTRE */

    function filtre() {
      let section = document.querySelector('.expertise .section-projects');
      let els = section.querySelectorAll('.section-projects .wrapper .container aside .container-filters .container-el .el');
      let lis = section.querySelectorAll('.section-projects .wrapper .container aside .container-filters .container-el .el li');
      els.forEach(function (el, index) {
        el.firstChild.addEventListener('click', function () {
          el.classList.toggle('style-open');
        })

      });

      lis.forEach(function (li) {
        li.addEventListener('click', () => {
          if (!li.classList.contains('style-active')) {
            li.classList.add('style-active');
          } else {
            li.classList.remove('style-active');
          }
        })
      });

      if (window.matchMedia("(max-width: 1200px)").matches) {
        let filters = document.querySelector('.section-projects .wrapper .container aside .container-filters');
        let head = document.querySelector('.section-projects .wrapper .container aside .container-filters .head');
        head.addEventListener('click', () => {
          if (!filters.classList.contains('style-active')) {
            filters.classList.add('style-active');
          } else {
            filters.classList.remove('style-active');
          }
        })
      }
    }

    /* SORT PROJECT */

    function sortDropdown() {
      let sort = document.querySelector('.section-projects .wrapper .container aside .container-sort');
      let head = document.querySelector('.section-projects .wrapper .container aside .container-sort .head');
      let li = document.querySelectorAll('.section-projects .wrapper .container aside .container-sort .dropdown .container-el .el ul li');


      head.addEventListener('click', () => {
        if (!sort.classList.contains('style-active')) {
          sort.classList.add('style-active');
        } else {
          sort.classList.remove('style-active');
        }
      })

      li.forEach(function (li, index) {
        li.addEventListener('click', function () {
          let thisIndex = index + 1;
          let arrayNextIndex = [];
          let nextIndex = 0;
          let nb = 1;
          document.querySelectorAll('.section-projects .wrapper .container aside .container-sort .dropdown .container-el .el').forEach(function (el) {
            el.querySelectorAll('ul li').forEach(function (li) {
              if (li.classList.contains('style-active')) {
                arrayNextIndex.push(nb);
              }
              nb++;
            });
          });

          arrayNextIndex.forEach(function (i) {
            if (thisIndex <= 2 && i >= 3) nextIndex = i;
            else if (thisIndex >= 3 && i <= 2) nextIndex = i;
          });

          document.querySelectorAll('.section-projects .wrapper .container aside .container-sort .dropdown .container-el .el').forEach(function (el) {
            el.querySelectorAll('ul li').forEach(function (li, index) {
              li.classList.remove('style-active');
            });
          });

          if (nextIndex === 0) {
            this.classList.add('style-active');
          } else if (thisIndex <= 2 && nextIndex >= 3) {
            this.classList.add('style-active');
            if (nextIndex === 3) document.querySelector('.container-sort .dropdown .container-el .el:last-child ul li:first-child').classList.add('style-active');
            else document.querySelector('.container-sort .dropdown .container-el .el:last-child ul li:last-child').classList.add('style-active');
          } else if (thisIndex >= 3 && nextIndex <= 2) {
            this.classList.add('style-active');
            if (nextIndex === 1) document.querySelector('.container-sort .dropdown .container-el .el:first-child ul li:first-child').classList.add('style-active');
            else document.querySelector('.container-sort .dropdown .container-el .el:first-child ul li:last-child').classList.add('style-active');
          } else if (thisIndex <= 2 && nextIndex <= 2) {
            this.classList.add('style-active');
          } else if (thisIndex >= 3 && nextIndex >= 3) {
            this.classList.add('style-active');
          }
        })
      })

    }

    /* TAGS SECTION PROJECTS */

    function tagProjects() {
      let containerTags = document.querySelector('.section-projects .container aside .container-tags');
      let lis = document.querySelectorAll('.section-projects .container aside .container-filters .container-el .el .dropdown ul li');

      lis.forEach(function (li, index) {
        li.addEventListener('click', () => {
          if (li.classList.contains('style-active')) {
            let divTag = document.createElement("div");
            divTag.classList.add("tag");
            divTag.id = 'tag' + index;
            let text = li.firstChild.textContent;
            divTag.innerHTML = '<span>' + text + '</span><div class="close"><div class="line"></div><div class="line"></div></div>';
            containerTags.append(divTag)
          }
          else if (!li.classList.contains('style-active')) {
            document.getElementById('tag' + index).remove()
          }
          var tags = containerTags.childNodes;
          tags.forEach(function (tag) {
            let close = tag.querySelector('.close');
            let idT = tag.id.split('g')[1];
            close.addEventListener('click', () => {
              if (lis[idT].classList.contains('style-active')) {
                lis[idT].click();
              }
            })
          })
        })
      })
    }

    const handleScroll = () => {
      windowScrollY = window.scrollY;
      animScroll();
    };

    window.addEventListener('load', () => {
      setTimeout(() => {
        sectionSlider();
        animScroll();
        sortDropdown();
        filtre();
        handleNewsletterOnSubmit(newsletterContainer, newsletterBtn);
        navScroll();
        updateFocusList();
        sliderOpenFocus(docs, setFocus);
        tagProjects();
      }, 300);
    });

    function loadHash() {
      if (getUrl.indexOf('#') > 1) {
        setTimeout(() => {
          sectionSlider();
          animScroll();
          sortDropdown();
          filtre();
          handleNewsletterOnSubmit(newsletterContainer, newsletterBtn);
          navScroll();
          updateFocusList();
          sliderOpenFocus(docs, setFocus);
          tagProjects();
        }, 300);
      }
    }
    loadHash();

    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) }

  }, [mount, lang]);



  return (
    <>
      <div className="lightbox-project">
        <div className="head">

        </div>
        <div className="content">

        </div>
      </div>
      <div className="container-mouse">
        <div className="container">
          <div className="circle"></div>
        </div>
      </div>
      <div className="mouse-pointer">
        <div className="container">
          <svg className="link-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
          </svg>
        </div>
      </div>

      <section className="section-hero" id="s1">
        <div className="wrapper">
          <div className="container">
            <div className="container-text">
              <div className="container-path">
                <div className="path">
                  <a href="/">{RichText.asText(doc.hero_pathtxt1)}</a>
                </div>
                <div className="sep">\</div>
                <div className="path">
                  <a href={getUrl}>{RichText.asText(doc.hero_pathtxt2)}</a>
                </div>
              </div>
              <div className="container-title">
                <h1>{RichText.asText(doc.hero_titlefirst)}</h1>
                <h1>{RichText.asText(doc.hero_titlelast)}</h1>
              </div>
            </div>
            <div className="container-img">
              <svg width="0" height="0" viewBox="0 0 640 640">
                <defs>
                  <clipPath id="svgPath" clipPathUnits="objectBoundingBox" transform="scale(0.0015625, 0.0015625)">
                    <path d="M1.01717e-05 640C0.18762 470.331 67.674 307.664 187.655 187.684C307.635 67.7032 470.313 0.206367 640 0L640 313.514C553.436 313.627 470.449 348.06 409.238 409.264C348.028 470.468 313.591 553.445 313.478 640L1.01717e-05 640Z" />
                  </clipPath>
                </defs>
              </svg>
              <img src={doc.hero_img.url} alt={doc.hero_img.alt} loading="lazy" />
            </div>
            <div className="obj-1"></div>
          </div>
        </div>
      </section>

      <section className='section-metier' id="s2">
        <div className="wrapper">
          <img className="obj-1" src="/img/expertise/section-metier/obj-1.svg" alt="" loading="lazy" />
          <div className="container-text">
            <h2>{RichText.asText(doc.job_title)}</h2>
            <RichText render={doc.job_text} />
          </div>
        </div>
      </section>

      <section className="section-num" id="s3">
        <div className="wrapper">
          <div className="container">
            <div className="container-title">
              <RichText render={doc.number_title} />
              <p>{RichText.asText(doc.number_text)}</p>
            </div>
            <div className="container-text">

              {doc.number_grp.map((item, i) => {
                if (i % 2 === 0) {
                  return (
                    <div key={i} className="container-case">
                      <div className="case">
                        <h3>{RichText.asText(item.title)}</h3>
                        <p>{RichText.asText(item.text)}</p>
                      </div>
                      <div className="case">
                        <h3>{RichText.asText(doc.number_grp[i + 1].title)}</h3>
                        <p>{RichText.asText(doc.number_grp[i + 1].text)}</p>
                      </div>
                    </div>

                  )
                } else { return (<React.Fragment key={i} />) }
              })}
              <div className="text">
                <div className="head">
                  <div className="source">{RichText.asText(doc.number_source)}</div>
                  <div className="container-infos">
                    <div className="info">{RichText.asText(doc.number_update)}</div>
                    <div className="info">{RichText.asText(doc.number_data)}</div>
                  </div>
                </div>
                <p>{RichText.asText(doc.number_text2)}</p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='section-ambition' id="s4">
        <div className="wrapper">
          <div className="container">
            <div className="container-title">
              <h2>{RichText.asText(doc.ambition_title)}</h2>
              <div className="text">
                <p>{RichText.asText(doc.ambition_subtitle)}</p>
                <p>{RichText.asText(doc.ambition_text)}</p>
              </div>
            </div>
            <div className="container-img">
              <img src={doc.ambition_img.url} alt={doc.ambition_img.alt} loading="lazy" />
            </div>
          </div>
        </div>
      </section>

      <section className='section-strategie' id="s5">
        <div className="wrapper">
          <div className="container">
            <div className="container-title">
              <h2>{RichText.asText(doc.strategy_title)}</h2>
              <div className="text">
                <p>{RichText.asText(doc.strategy_subtitle)}</p>
                <p>{RichText.asText(doc.strategy_text)}</p>
              </div>
            </div>
            <div className="container-img">
              <img src={doc.strategy_img.url} alt={doc.strategy_img.alt} loading="lazy" />
            </div>
          </div>
        </div>
      </section>

      <section className='section-desc' id="s6">
        <div className="wrapper">
          <div className="container-desc">
            {doc.desc_grp.map((item, i) => (
              <div key={i} className="desc">
                <div className="name">
                  <h2>{RichText.asText(item.title)}</h2>
                </div>
                <div className="text">
                  <div className="content">
                    <RichText render={item.text1} />
                    <RichText render={item.text2} />
                  </div>
                  <div className="infos">
                    <RichText render={item.data} />
                    <RichText render={item.rate} />
                  </div>
                </div>
                <div className="img">
                  <img src={item.img.url} alt={item.img.alt} loading="lazy" />
                </div>
              </div>
            ))}
          </div>
          <div className="container-text">
            <p>{RichText.asText(doc.desc_text)}</p>
          </div>
          <div className="foot">
            <p>{RichText.asText(doc.desc_question)}</p>
            <p>{RichText.asText(doc.desc_contactfirst)}<a href={cl(doc.desc_lnklnk)}>{RichText.asText(doc.desc_lnktxt)}</a>{RichText.asText(doc.desc_contactlast)}</p>
          </div>
        </div>
      </section>

      <section className='section-investissements' id="s7">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.investment_title)}</h2>
            <p>{RichText.asText(doc.investment_subtitle)}</p>
          </div>
        </div>
        <div className="container-el">
          {doc.body2.map((slice, i) => (
            <div key={i} className="el" data-role='animContainer'>
              <div className="container-img">
                <img src={slice.primary.img.url} alt={slice.primary.img.alt} loading="lazy" />
              </div>
              <div className="wrapper">
                <div className="foot">
                  <div className="container-text">
                    <div className="title">{RichText.asText(slice.primary.title)}</div>
                    {slice.items.map((item, l) => (
                      <div key={l} className="text">
                        <div className="head">{RichText.asText(item.subtitle)}</div>
                        <p>{RichText.asText(item.text)}</p>
                      </div>
                    ))}
                  </div>
                  <div className="container-nav">
                    <div className="nav">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                      </svg>
                    </div>
                    <div className="nav">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <use xlinkHref="/img/common/icn-arrow-1.svg#content"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="section-slider" id="s8">
        <div className="container-slider">
          <div className="slider">
            <div className="container-el">
              {doc.slider_grp1.map((item, i) => (
                <React.Fragment key={i}>
                  {docs.map((art, a) => {
                    let t = JSON.parse(art.node.dataString);
                    if (item.link.uid === art.node.uid) {
                      return (
                        <div key={a} className="el" data-href={art.node.uid}>
                          <img src={t.bthumbnail_img.url} alt={t.bthumbnail_img.alt} loading="lazy" />
                          <div className="text">
                            <div className="title">{RichText.asText(t.content_title)}</div>
                            <p>{RichText.asText(t.bthumbnail_desc)}</p>
                          </div>
                        </div>
                      )
                    } else { return (<React.Fragment key={a} />) }
                  })}
                </React.Fragment>
              ))}
            </div>
            <div className="container-shape">
              <div className="shape"></div>
              <div className="shape"></div>
            </div>
            <div className="container-area">
              <div className="area action-prev"></div>
              <div className="area"></div>
              <div className="area action-next"></div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <div className="container-infos">
              {doc.slider_grp1.map((item, i) => (
                <div key={i} className="li-infos">
                  {docs.map((art, a) => {
                    let st = "";
                    let ty = "";
                    let typos = [];
                    let re = "";
                    let regs = [];
                    let co = "";
                    let t = JSON.parse(art.node.dataString);
                    if (item.link.uid === art.node.uid) {
                      strategy.map((strat, s) => {
                        if (strat.node.slugs[0] === t.global_strategy.slug) {
                          st = JSON.parse(strat.node.dataString).name;
                        }

                      });
                      typology.map((typo, y) => {
                        for (let i = 0; i < t.global_investments.length; i++) {
                          if (typo.node.slugs[0] === t.global_investments[i].typo.slug) {
                            ty = JSON.parse(typo.node.dataString).name;
                            typos.push(ty[0].text + " ");
                          }
                        }
                      });
                      region.map((reg, r) => {
                        for (let i = 0; i < t.global_regions.length; i++) {
                          if (reg.node.slugs[0] === t.global_regions[i].region.slug) {
                            re = JSON.parse(reg.node.dataString).name;
                            regs.push(re[0].text + " ");
                          }
                        }
                        if (reg.node.slugs[0] === t.global_regions[0].region.slug) {
                          country.forEach(function (cou, c) {
                            if (cou.node.slugs[0] === JSON.parse(reg.node.dataString).country.slug) {
                              co = JSON.parse(cou.node.dataString).name;
                            }
                          })
                        }
                      });
                      return (
                        <React.Fragment key={a}>
                          <div className="info">{RichText.asText(st)}</div>
                          <div className="info">{typos}</div>
                          <div className="info">{regs} - {RichText.asText(co)}</div>
                        </React.Fragment>
                      )
                    }

                  })}
                </div>
              ))}
            </div>
            <div className="container-action">
              <div className="play style-play" data-interval="4000">
                <img src="/img/home/section-slider/icn-play.svg" alt="" loading="lazy" />
                <img src="/img/home/section-slider/icn-pause.svg" alt="" loading="lazy" />
              </div>
              <div className="container-tab">
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
                <div className="tab"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-p' id="s9">
        <div className="wrapper">
          <div className="container">
            <div className="container-title">
              <h2>{RichText.asText(doc.listing_title)}</h2>
            </div>
            <div className="container-text">
              <RichText render={doc.listing_text} />
            </div>
          </div>
        </div>
      </section>

      <section className='section-projects' id="s10">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.projects_title)}</h2>
          </div>
          <div className="container">
            <aside>
              <div className="search">
                <input type="text" placeholder={RichText.asText(doc.projects_search)} onInput={(e) => focusSearch(e.target.value)} />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <use xlinkHref="/img/common/icn-loupe.svg#content"></use>
                </svg>
              </div>
              <div className="container-tags">
              </div>
              <div className="container-filters">
                <div className="head">
                  <span>{RichText.asText(doc.projects_filter)}</span>
                  <img src="/img/expertise/section-projects/drop.svg" alt="drop" loading="lazy" />
                </div>
                <div className="container-el">
                  <div className="el">
                    <div className="title">
                      <span>{RichText.asText(doc.projects_region)}</span>
                      <div className="cross">
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <ul>
                        {sortRegion().map((r, i) => {
                          return (
                            <li key={i} onClick={() => { updateRegionOrTypology(uRegion, r[0]) }}><div className="name">{r[0]}</div></li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="el">
                    <div className="title">
                      <span>{RichText.asText(doc.projects_typologie)}</span>
                      <div className="cross">
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <ul>
                        {typology.map((typ, i) => {
                          return (
                            <li key={i} onClick={() => { updateRegionOrTypology(uTypo, RichText.asText(JSON.parse(typ.node.dataString).name)) }}><div className="name">{RichText.asText(JSON.parse(typ.node.dataString).name)}</div></li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="el">
                    <div className="title">
                      <span>{RichText.asText(doc.projects_strategy)}</span>
                      <div className="cross">
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <ul>
                        {strategy.map((strat, i) => {
                          return (
                            <li key={i} onClick={() => { updateStrategyOrStatus(uStrat, RichText.asText(JSON.parse(strat.node.dataString).name)) }}><div className="name">{RichText.asText(JSON.parse(strat.node.dataString).name)}</div></li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="el">
                    <div className="title">
                      <span>{RichText.asText(doc.projects_surface)}</span>
                      <div className="cross">
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <ul>
                        <InputRange
                          minValue={uSurfMin}
                          maxValue={uSurfMax}
                          value={stateSurf.value}
                          step={5}
                          onChange={value => updateData(uSurf, setStateSurf, 4, value)} />
                      </ul>
                    </div>
                  </div>
                  <div className="el">
                    <div className="title">
                      <span>{RichText.asText(doc.projects_date)}</span>
                      <div className="cross">
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <ul>
                        <InputRange
                          id="inputrange-date"
                          minValue={uDateMin}
                          maxValue={uDateMax}
                          onChange={value => updateData(uDate, setStateDate, 5, value)}
                          value={stateDate.value} />
                      </ul>
                    </div>
                  </div>
                  <div className="el">
                    <div className="title">
                      <span>{RichText.asText(doc.projects_statut)}</span>
                      <div className="cross">
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <ul>
                        {doc.projects_av.map((status, i) => (
                          <li key={i} onClick={() => { updateStrategyOrStatus(uStatus, RichText.asText(status.av)) }} ><div className="name">{RichText.asText(status.av)}</div></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-sort">
                <div className="head">
                  <span>{RichText.asText(doc.projects_sort)}</span>
                  <img src="/img/expertise/section-projects/drop.svg" alt="drop" loading="lazy" />
                </div>
                <div className="dropdown">
                  <div className="container-el">
                    <div className="el">
                      <ul>
                        {doc.projects_grp1.map((item, i) => (
                          <li key={i} onClick={() => { updateSortData(usDate, item.select) }}>
                            <span>{RichText.asText(item.text)}</span>
                            <img src="/img/expertise/section-projects/check.svg" alt="check" />
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="el">
                      <ul>
                        {doc.projects_grp2.map((item, i) => (
                          <li key={i} onClick={() => { updateSortData(usAlpha, item.select) }}>
                            <span>{RichText.asText(item.text)}</span>
                            <img src="/img/expertise/section-projects/check.svg" alt="check" />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
            <div className="container-project">
              <div className="container-el" dangerouslySetInnerHTML={{ __html: focusList }} />
            </div>
          </div>
        </div>
      </section>

      <section className="section-newsletter" id="s11">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.newsletter_title)}</h2>
            <p>{RichText.asText(doc.newsletter_text)}</p>
          </div>
          <form onSubmit={(e) => handleOnSubmit(e, getUrl)}>
            <div className="container-form">
              <div className="container">
                <div className="container-input">
                  {newsletterFields.map((field) => (
                      <div className="input" key={field.id}>
                        <input
                          type={field.id === "nEmail" ? "email" : "text"}
                          id={field.id}
                          onChange={handleOnChange}
                        />
                        <span className="placeholder">
                          {RichText.asText(field.content)} <i>*</i>
                        </span>
                        <span className="error">
                          Champ invalide
                        </span>
                      </div>
                    ))
                  }
                  <div className="input">
                    <input
                    name="cc-num"
                    className="hidden"
                    required=""
                    value="HubspotCollectedFormsWorkaround"
                    id="cc-num"
                    onChange={handleOnChange}
                    readOnly
                  />
                  </div>
                </div>
                <button>
                  <span className='btn-text'>{RichText.asText(doc.newsletter_btntxt)}</span>
                  <div className="loading">
                    <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-load.svg#content"></use>
                    </svg>
                    <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                    </svg>
                  </div>
                </button>
              </div>
              <p className="rqd">
                <i>*</i> {RichText.asText(doc.newsletter_rqd)}
              </p>
              <div className="container-checkbox">
                <div className="checkbox">
                  <input type="checkbox" id="check1" onChange={handleOnChange}/>
                  <div className="text">
                    <p id="check1Txt">{RichText.asText(doc.newsletter_info1)}</p>
                    <a href={cl(doc.newsletter_lnklnk)} target={doc.newsletter_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.newsletter_lnktxt)}</a>
                  </div>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="check2" />
                  <div className="text">
                    <p>{RichText.asText(doc.newsletter_info2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

    </>
  );
};

const InitExpertise = ({ data }) => {
  const lang = data.prismicExpertise.lang.substring(0, 2);
  const header = JSON.parse(data.prismicHeader.dataString);
  const footer = JSON.parse(data.prismicFooter.dataString);
  const categories = data.allPrismicCategories.edges;
  const doc = JSON.parse(data.prismicExpertise.dataString);
  const docs = data.allPrismicFocus.edges;
  const articles = data.allPrismicArticle.edges;
  const strategy = data.allPrismicStrategy.edges;
  const typology = data.allPrismicInvestmentTypology.edges;
  const region = data.allPrismicRegions.edges;
  const country = data.allPrismicCountries.edges;

  const [focus, setFocus] = useState(null);

  if (!doc || !header || !footer) return null;

  return (
    <>
      <Head doc={doc} lang={lang} />
      <div className="expertise">
        <Layout
          focus={focus}
          setFocus={setFocus}
          docs={docs}
          header={header}
          articles={articles}
          cat={categories}
          footer={footer}
          lang={lang}
          type={"expertise"}
        >
          <main>
            <Expertise
              focus={focus}
              setFocus={setFocus}
              doc={doc}
              lang={lang}
              docs={docs}
              cat={categories}
              strategy={strategy}
              typology={typology}
              region={region}
              country={country}
            />
          </main>
        </Layout>
      </div>
    </>
  );
};

export default InitExpertise;

export const query = graphql`
query callExpertise($lang: String) {
  prismicHeader(lang: { eq : $lang }) {
    dataString
  }
  allPrismicCategories(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicStrategy(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicInvestmentTypology(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicRegions(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            prismicId
            slugs
            dataString
        }
    }
  }
  allPrismicCountries(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicFocus(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            prismicId
            dataString
            first_publication_date
            uid
        }
    }
}
allPrismicArticle(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            dataString
            first_publication_date
        }
    }
  }
  prismicExpertise(lang: {eq: $lang}) {
      dataString
      lang
      uid
  }
  prismicFooter(lang: {eq: $lang}) {
    dataString
  }
}
`